import { useEffect, useState } from 'react';

import appConfig from 'constants/appConfig';
import { useIntercom } from 'hooks/useIntercom';
import { useWalkMe } from 'hooks/useWalkMe';
import { useAuthContext } from 'providers/authorization/AuthProvider';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useGoogleTagManagerContext } from 'providers/googleTagManager/GoogleTagManager';
import SiemensAnalyticsModule from 'services/SiemensAnalyticsService';
import { useGetAuroraOptOutQuery } from 'services/queries/analyticsSettings.query';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { Nullable } from 'types';
import { AuroraOptOut } from 'types/optOut';

import useUserLogins from './useUserLogins';

const ANALYTICS_SESSION_TIMEOUT_MINUTES = 90;
const ANALYTICS_SESSION_TIMEOUT_SECONDS = ANALYTICS_SESSION_TIMEOUT_MINUTES * 60;
const useAnalytics = () => {
    const [optOut, setOptOut] = useState<Nullable<AuroraOptOut>>(null);

    const { userLogins } = useUserLogins();
    const { user } = useUserDataContext();
    const { idToken, isAuthenticated } = useAuthContext();

    const { data: featureFlags } = useFeatureFlagsQuery();
    const { data: auroraOutData, isLoading: isAuroraLoading } = useGetAuroraOptOutQuery();

    const isInternal = !(
        featureFlags?.Analytics_SiemensAuroraAnalyticsEnabled &&
        appConfig.SIEMENS_ANALYTICS_USE_PRODUCTION === 'true'
    );

    const initIntercom = useIntercom();
    const initWalkMe = useWalkMe();
    const { initGtm, addToUserDataLayer } = useGoogleTagManagerContext();
    useEffect(() => {
        const isOptOutOptionSelected = auroraOutData && auroraOutData.pepOptOut !== null;
        if (isAuthenticated && user && featureFlags) {
            initGtm(user, isInternal);
            initIntercom(user);
            featureFlags.Analytics_SiemensAuroraAnalyticsEnabled
                ? isOptOutOptionSelected &&
                  initWalkMe(user, featureFlags.WalkMeIntegration, isInternal)
                : initWalkMe(user, featureFlags.WalkMeIntegration, isInternal);
        }
    }, [user, isAuthenticated, featureFlags, auroraOutData]);

    useEffect(() => {
        if (featureFlags?.Analytics_SiemensAuroraAnalyticsEnabled) {
            if (auroraOutData && !isAuroraLoading) {
                setOptOut(auroraOutData);
            }
        }
    }, [auroraOutData, isAuroraLoading]);

    useEffect(() => {
        if (isAuthenticated && user && optOut && featureFlags) {
            let productTier = '';
            if (user.samAuthTier) {
                const filteredAuthTier = user.samAuthTier.filter((x: string) =>
                    x.startsWith('Connect')
                );
                productTier = filteredAuthTier.length > 0 ? filteredAuthTier[0] : '';
            }

            if (featureFlags.Analytics_SiemensAuroraAnalyticsEnabled) {
                optOut.dpeOptOut && SiemensAnalyticsModule.optOutDigitalProductExperience();
                optOut.pepOptOut && SiemensAnalyticsModule.optOutProductExcellenceProgram();

                addToUserDataLayer('dpeOptOut', optOut.dpeOptOut);
                addToUserDataLayer('pepOptOut', optOut.pepOptOut);

                if (!optOut.dpeOptOut) {
                    addToUserDataLayer('userLogins', userLogins.toString());
                }

                SiemensAnalyticsModule.init({
                    customerId: user.customerId,
                    userId: user.userId ?? '',
                    productKey: appConfig.ANALYTICS_PRODUCT_KEY,
                    userEmail: user.email,
                    internalUsage: !(appConfig.SIEMENS_ANALYTICS_USE_PRODUCTION === 'true') ?? true,
                    sessionTimeoutInSec: ANALYTICS_SESSION_TIMEOUT_SECONDS,
                    productTier: productTier,
                    gatherPIIData: featureFlags.Analytics_SiemensAnalyticsPIIGathering ?? false,
                });
            }
        }
    }, [isAuthenticated, idToken, user, optOut, featureFlags]);
};

export default useAnalytics;
