import { useState } from 'react';

import WalkmeWidgetProd from 'components/externals/Intercom/walkme.widget.prod';
import WalkmeWidgetTest from 'components/externals/Intercom/walkme.widget.test';
import appConfig from 'constants/appConfig';
import { User } from 'providers/currentUser/UserDataProvider';
import SiemensAnalyticsModule from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';

export const useWalkMe = () => {
    const [initialized, setInitialized] = useState<boolean>(false);
    return (userData: User, isWalkMeEnabled: boolean, isInternal: boolean) => {
        try {
            if (!isWalkMeEnabled || initialized) {
                return;
            }

            if (appConfig.WALK_ME_ENV_TYPE === 'prod') {
                WalkmeWidgetProd();
            } else {
                WalkmeWidgetTest();
            }
            setInitialized(true);

            window.userId = userData.userId;
            window.ecaId = userData.customerId;
            window.analyticsEnv = isInternal ? 'internal' : 'external';
            try {
                window.analyticsUserId = SiemensAnalyticsModule.getUserHashId();
            } catch (e) {
                console.error('Unable to get user hash id from analytics');
            }
        } catch (e) {
            console.error('Unable to init WalkMe widget');
        }
    };
};
