import { Tab } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import Box from 'components/atoms/Box';
import { ISelectList } from 'components/atoms/ComboBox';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { StyledTabs } from 'components/atoms/StyledTabs';
import SideNav from 'components/molecules/SideNav';
import VariantComboBox, { Variants } from 'components/molecules/VariantComboBox/VariantComboBox';
import { ProjectSideNav } from 'components/organisms/AddUpdateProject/ProjectSideNav';
import { SideNavMode } from 'components/organisms/AddUpdateProject/enums/sideNavMode';
import VersionsChooser from 'components/organisms/ProjectVersion/VersionsChooser';
import { getVariants } from 'components/templates/BomView/BomVariants/useVariants';
import Design from 'components/templates/Design';
import designQuery from 'components/templates/Design/api/query/design.query';
import FileManagement from 'components/templates/FileManagement';
import MainLayout from 'components/templates/MainLayout';
import PartList from 'components/templates/PartList/PartList';
import ProjectHistory from 'components/templates/ProjectHistory';
import ProjectUsers from 'components/templates/ProjectUsers/ProjectUsers';
import appConfig, { getLocalstorageCleanupInterval } from 'constants/appConfig';
import { useLocalStorageCleanup } from 'hooks/useLocalstorageCleanup';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { ExportPartlistProvider } from 'providers/exportPartlistProvider/ExportPartlistProvider';
import { FullScreenContext } from 'providers/fullScreen/FullScreenProvider';
import { useCollaborationSpacePermission, useProjectPermission } from 'providers/permissions';
import RevisionsActionStatusProvider from 'providers/revisionsAction/RevisionsActionStatusProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { useGetProjectQuery } from 'services/queries/project.query';
import { isScanStatusInfected } from 'shared/utility';
import { Path } from 'types/paths.enum';
import { CollaborationSpacePermissions, ProjectPermissions } from 'types/permissions';

import { BreadCrumb } from './BreadCrumb';
import { ProjectDescription } from './component/ProjectDescription';
import useAddProjectToRecent from './hooks/useAddProjectToRecent';

enum ProjectTabs {
    DESIGN,
    PARTLIST,
    FILES,
    USERS,
    HISTORY,
}

export function Project() {
    const localstorageCleanupInterval = getLocalstorageCleanupInterval();
    useLocalStorageCleanup(localstorageCleanupInterval);
    const { projectId, revisionId = '0', tab = '0' } = useParams();
    const [isRefetchingForNewVersion, setRefetchingForNewVersion] = useState(false);
    const [revisionIdInternal, setRevisionIdInternal] = useState('');
    const [selectedTabId, setSelectedTabId] = useState(parseInt(tab));
    const [showEditSidenav, setShowEditSidenav] = useState(SideNavMode.closed);
    const fullScreenMode = useContext(FullScreenContext);
    const { collaborationSpaceId } = useUserDataContext();
    const navigate = useNavigate();
    const { setProgressBar } = useProgressBar();
    const { hasProjectPermission } = useProjectPermission(collaborationSpaceId, projectId!);
    const { hasCollaborationSpacePermission } =
        useCollaborationSpacePermission(collaborationSpaceId);
    const { data: featureFlags } = useFeatureFlagsQuery();
    const [currentVariant, setCurrentVariant] = useState<string | undefined>(undefined);

    const { data: variantsData } = getVariants(revisionIdInternal);
    const variants: Variants[] | undefined = variantsData?.reduce(
        (acc, curr) => [...acc, { label: curr, name: curr, value: curr }],
        [{ label: 'Default', value: 'Default' }]
    );
    const isRevisionSet = revisionIdInternal !== '';

    useEffect(() => {
        if (isNaN(selectedTabId) || selectedTabId < 0 || selectedTabId > ProjectTabs.HISTORY) {
            navigate(Path.ERROR_404);
        }
    }, [selectedTabId]);

    function ProjectsTabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const setCurrentVersionHandler = (data: Event) => {
        navigate(
            `${Path.PROJECTS}/${projectId}/${
                (data as CustomEvent).detail.eventArgs.current
            }/${selectedTabId}`,
            { replace: true }
        );
    };

    useEffect(() => {
        document.addEventListener('setCurrentVersion', setCurrentVersionHandler);
        return () => {
            document.removeEventListener('setCurrentVersion', setCurrentVersionHandler);
        };
    }, [setCurrentVersionHandler]);

    const {
        data: currentRevision,
        isError: isCurrentRevisionError,
        error: currentRevisionError,
        isLoading: isCurrentRevisionLoading,
    } = designQuery({
        projectInfo: { collaborationSpaceId, projectId, revisionId },
    });

    useEffect(() => {
        if (revisionIdInternal !== '') {
            if (selectedTabId <= ProjectTabs.HISTORY) {
                navigate(`${Path.PROJECTS}/${projectId}/${revisionIdInternal}/${selectedTabId}`, {
                    replace: true,
                });
            }
        }
    }, [selectedTabId, revisionIdInternal]);

    useEffect(() => {
        fullScreenMode.setFullScreen(false);
    }, [selectedTabId]);

    const {
        data: currentProjectData,
        isLoading: isLoadingProjectData,
        isFetching: isFetchingProjectData,
        isError: isErrorProjectData,
        refetch,
    } = useGetProjectQuery({
        collaborationSpaceId,
        projectId: projectId!,
    });

    useEffect(() => {
        if (currentProjectData?.scanInfo?.scanStatus === 'PENDING_SCAN') {
            refetch();
        }
    }, [currentProjectData]);

    const { mutate } = useAddProjectToRecent();

    useEffect(() => {
        if (featureFlags?.XpeditionSupportFlag && collaborationSpaceId) {
            mutate({ collaborationSpaceId, projectId: projectId! });
        }
    }, [collaborationSpaceId]);

    useEffect(() => {
        if (isCurrentRevisionLoading || isLoadingProjectData) {
            return;
        }
        if (revisionId === '0') {
            if (currentRevision) {
                setRevisionIdInternal(currentRevision.versionNumber);
            } else {
                setRevisionIdInternal('0');
            }
            setRefetchingForNewVersion(false);
        } else if (
            !isErrorProjectData &&
            isCurrentRevisionError &&
            currentRevisionError.response?.status === 404
        ) {
            navigate(`${Path.PROJECTS}/${projectId}/${revisionId}/${Path.MISSING_ASSET}`);
        } else {
            setRevisionIdInternal(revisionId);
            setRefetchingForNewVersion(false);
        }
    }, [
        isCurrentRevisionLoading,
        currentRevisionError,
        isCurrentRevisionError,
        revisionId,
        isRefetchingForNewVersion,
        isLoadingProjectData,
    ]);

    useEffect(() => {
        setProgressBar(isCurrentRevisionLoading || isLoadingProjectData || isFetchingProjectData);
    }, [isCurrentRevisionLoading, isLoadingProjectData, isFetchingProjectData]);

    const putPartList = () => (
        <ExportPartlistProvider>
            <PartList
                isCurrentRevisionLoading={isCurrentRevisionLoading}
                bomVisibility={selectedTabId !== ProjectTabs.PARTLIST}
                collaborationspaceid={collaborationSpaceId}
                projectid={projectId}
                projectversion={revisionIdInternal}
                iscloudenvironment={appConfig.IS_CLOUD_ENVIRONMENT}
                isRevisionInfected={isScanStatusInfected(currentRevision?.scanInfo)}
                currentVariant={currentVariant}
            />
        </ExportPartlistProvider>
    );
    const putFileManagementView = () => (
        <FileManagement
            projectname={currentProjectData?.name}
            collaborationspaceid={collaborationSpaceId}
            projectid={projectId}
            rootid='file-management-root'
        />
    );

    const putHistoryView = () => (
        <ProjectHistory
            revision={revisionIdInternal}
            projectName={currentProjectData?.name ?? ''}
            thumbnail={currentProjectData?.thumbnailUri ?? ''}
        />
    );

    const putVersionChooser = () => {
        return revisionIdInternal && isRevisionSet && revisionId !== '0' && projectId ? (
            <Box>
                <Box css={{ fontWeight: '600' }}>Project Version</Box>
                <VersionsChooser
                    collaborationSpaceId={collaborationSpaceId}
                    projectId={projectId}
                    revisionId={+revisionIdInternal}
                    permissions={{
                        canRestoreRevision: hasProjectPermission(
                            ProjectPermissions.ProjectRestoreRevision
                        ),
                        canDeleteRevision: hasProjectPermission(
                            ProjectPermissions.ProjectDeleteRevision
                        ),
                    }}
                />
            </Box>
        ) : null;
    };

    const onProjectsClick = () => {
        triggerAnalyticsClickEvent(
            analyticsConsts.Actions.projects,
            analyticsConsts.Categories.navigation,
            analyticsConsts.Sources.breadcrumb
        );
        navigate(Path.PROJECTS);
    };

    const onProjectEdit = () => {
        setShowEditSidenav(SideNavMode.editProject);
    };

    const usersView = collaborationSpaceId && projectId && <ProjectUsers projectId={projectId} />;
    const getView = (param: ProjectTabs) => {
        switch (param) {
            case ProjectTabs.DESIGN:
                return <Design />;
            case ProjectTabs.FILES:
                return putFileManagementView();
            case ProjectTabs.USERS:
                return usersView;
            case ProjectTabs.HISTORY:
                return putHistoryView();
            default:
                return null;
        }
    };

    return (
        <MainLayout>
            <RevisionsActionStatusProvider>
                {!isLoadingProjectData ? (
                    <Box css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!!showEditSidenav && (
                            <SideNav
                                title={'Edit Project'}
                                children={
                                    <ProjectSideNav
                                        collaborationSpaceId={collaborationSpaceId}
                                        selected={currentProjectData!}
                                        sideNavState={SideNavMode.editProject}
                                        setSideNavState={setShowEditSidenav}
                                    />
                                }
                                open={!!showEditSidenav}
                                setOpen={() => {
                                    setShowEditSidenav(SideNavMode.closed);
                                }}
                                modal={false}
                            />
                        )}
                        <Box css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Box
                                css={(theme) => ({
                                    margin: theme.spacing(5, 0, 3),
                                })}
                            >
                                <BreadCrumb
                                    projectName={currentProjectData?.name ?? ''}
                                    onProject={onProjectsClick}
                                    onEdit={
                                        hasCollaborationSpacePermission(
                                            CollaborationSpacePermissions.CollaborationSpaceProjectEdit
                                        )
                                            ? onProjectEdit
                                            : undefined
                                    }
                                />
                            </Box>
                            <Box css={{ ...(fullScreenMode.fullScreen && { display: 'none' }) }}>
                                <ProjectDescription
                                    content={currentProjectData?.description ?? ''}
                                />
                                <StyledTabs
                                    value={!fullScreenMode.fullScreen && selectedTabId}
                                    onChange={(_e, value) => setSelectedTabId(value)}
                                    aria-label='Click the tab!'
                                    style={{ marginBottom: '0' }}
                                >
                                    <Tab label='Design' {...ProjectsTabProps(ProjectTabs.DESIGN)} />
                                    <Tab label='BOM' {...ProjectsTabProps(ProjectTabs.PARTLIST)} />
                                    <Tab label='Files' {...ProjectsTabProps(ProjectTabs.FILES)} />
                                    <Tab label='Users' {...ProjectsTabProps(ProjectTabs.USERS)} />
                                    <Tab
                                        label='History'
                                        {...ProjectsTabProps(ProjectTabs.HISTORY)}
                                    />
                                </StyledTabs>
                                <Box
                                    css={{
                                        fontSize: '12px',
                                    }}
                                >
                                    {
                                        <Box
                                            css={{
                                                display: 'none',
                                                marginTop: '12px',
                                                ...((selectedTabId === ProjectTabs.DESIGN ||
                                                    selectedTabId === ProjectTabs.PARTLIST) && {
                                                    display: 'flex',
                                                }),
                                            }}
                                        >
                                            {putVersionChooser()}
                                            <Box
                                                css={{
                                                    ...(selectedTabId !== ProjectTabs.PARTLIST && {
                                                        display: 'none',
                                                    }),
                                                }}
                                            >
                                                {variants && variants?.length > 1 && (
                                                    <VariantComboBox
                                                        variants={variants}
                                                        onChange={(value) => {
                                                            setCurrentVariant(
                                                                (value as ISelectList).name
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box
                                css={{
                                    fontSize: '0.75rem',
                                    fontWeight: 400,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    overflowY: 'hidden',
                                }}
                            >
                                {revisionIdInternal !== '' ? getView(selectedTabId) : null}
                                {putPartList()}
                            </Box>
                        </Box>
                    </Box>
                ) : null}
            </RevisionsActionStatusProvider>
        </MainLayout>
    );
}

export default Project;
